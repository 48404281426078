import PageView from '../components/collection_campaign_page/PageView';
import GetACardModal from '../components/GetACardModal/GetACardModal';

void (window.pageView = new PageView({
    el: '.Carousels',
    viewId: 'home',
    bus: window.globalEvents,
    room: window.OverDrive.currentRoom,
    featureManager: window.featureManager,
    patronSettings: window.patronSettings,
}));

$(() => {
    // Only one of these modals should show at a time. This is driven by the "library" middleware
    if (window.OverDrive.showAccessEndingModal) {
        window.onReactComponentsReady((event) => {
            event.detail.renderAccessEndingModal('access-ending-modal', {
                open: true,
                isChrome: bowser && bowser.chrome,
            });
        });
    }

    if (window.OverDrive.showTryLibbyModal) {
        const isWindows =
            window.navigator.userAgent.match(/Win/) && !bowser.windowsphone;
        const isSunsetMayDateSet =
            window.featureManager.isEnabled('sunset-date-set');
        const isSunsetKoreaDateSet = window.featureManager.isEnabled(
            'odsunset-korea-2023'
        );
        const tabletSupportsLibby = !window.OverDrive.isUnsupportedKindle;

        window.onReactComponentsReady((event) => {
            event.detail.renderTryLibbyModal('home-intercept-modal', {
                isKindle: window.OverDrive.isKindle,
                isSunsetMayDateSet,
                isSunsetKoreaDateSet,
                tabletSupportsLibby,
                isWindows,
                open: true,
            });
        });
    }

    if (window.OverDrive.showGetACardModal) {
        new GetACardModal();
    }
});
